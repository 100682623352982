


































































































import { Component, Vue } from "vue-property-decorator";
import cardBox from "@/components/card-box/index.vue";
import { uuid } from "@/tool";
import { queryExamRoom, setExamRoom } from "@/api/examRoom";
import { ExamRoom, School, UpgradeLevel } from "@/tool/interface-index";
import { getSchoolList } from "@/api/school";
@Component({
  name: "CreateExamRoom",
  components: {
    cardBox,
  },
})
export default class extends Vue {
  private ruleForm: ExamRoom = {
    examRoomId: uuid(),
    examRoomName: "",
    schoolId: "",
    contactPerson: "",
    contactPersonPhone: "",
    contactPersonEmail: "",
    contactPerson02: "",
    contactPersonPhone02: "",
    contactPersonEmail02: "",
    registrationCode: "",
    softwareVersion: "",
    upgradeLevel: UpgradeLevel.none,
  };
  rules = {
    examRoomName: [
      { required: true, message: "请输入考场名称", trigger: "change" },
    ],
    schoolId: [{ required: true, message: "请选择学校", trigger: "change" }],
    contactPerson: [
      { required: true, message: "请输入联系人名称", trigger: "change" },
    ],
    contactPersonEmail: [
      { required: true, message: "请输入联系人邮箱", trigger: "change" },
    ],
    contactPersonPhone: [
      { required: true, message: "请输入联系人电话", trigger: "change" },
    ],
    registrationCode: [
      { required: true, message: "请输入注册码", trigger: "change" },
    ],
  };
  private pageType: string = "";
  private title: string = "";
  private schoolData: Array<School> = [];

  private get type(): string {
    if (this.pageType == "add") {
      this.title = "创建考场";
      return "立即创建";
    }
    if (this.pageType == "edit") {
      this.title = "编辑考场列表";
      return "保 存";
    }
    return "立即创建";
  }
  submitForm() {
    (this as any).$refs.ruleForm.validate((valid: any) => {
      if (valid) {
        setExamRoom(this.ruleForm).then((res: any) => {
          if (res.code == 0) {
            this.$message({
              type: "success",
              message: "创建成功!",
            });
          } else {
            this.$message({
              type: "warning",
              message: res.msg,
            });
          }
        });
      } else {
        console.log("error submit!!");
        return false;
      }
    });
  }
  doGoBackClick() {
    this.$router.go(-1);
  }
  resetForm(formName: string | number) {
    (this as any).$refs.ruleForm.resetFields();
  }
  private handleChangeSchool(value: string) {
    const idx = this.schoolData.findIndex((school: School) => {
      return value == school.schoolId;
    });
    if (idx < 0) {
      return;
    }
    const school: School = this.schoolData[idx];
    if (!this.ruleForm.contactPerson) {
      this.ruleForm.contactPerson = school.contactPerson;
    }
    if (!this.ruleForm.contactPersonPhone) {
      this.ruleForm.contactPersonPhone = school.contactPersonPhone;
    }
    if (!this.ruleForm.contactPersonEmail) {
      this.ruleForm.contactPersonEmail = school.contactPersonEmail;
    }
  }
  private async getSchoolData() {
    const { data } = await getSchoolList({
      pageSize: 9999,
      curPage: 1,
    });
    this.schoolData = data.items;
  }

  private async __init() {
    this.pageType = this.$route.query.type as string;
    await this.getSchoolData();
    if (this.$route.query.examRoomId) {
      const { data } = await queryExamRoom(
        this.$route.query.examRoomId as string
      );
      this.ruleForm = data;
    } else if (this.pageType == "add") {
      this.ruleForm.examRoomName =
        (this.$route.query.quickExamRoomName as string) || "机房";
      if (this.$route.query.quickSchoolName) {
        const schools = this.schoolData.filter((item) => {
          return item.schoolName.includes(this.$route.query.quickSchoolName as string)
        });
        if (schools.length == 1) {
          const school: School = schools[0];
          this.ruleForm.schoolId = schools[0].schoolId;
          if (!this.ruleForm.contactPerson) {
            this.ruleForm.contactPerson = school.contactPerson;
          }
          if (!this.ruleForm.contactPersonPhone) {
            this.ruleForm.contactPersonPhone = school.contactPersonPhone;
          }
          if (!this.ruleForm.contactPersonEmail) {
            this.ruleForm.contactPersonEmail = school.contactPersonEmail;
          }
        }
      }
    }
  }

  mounted() {
    this.__init();
  }
}
