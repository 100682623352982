import request from '@/utils/request'

export const getSchoolList = (params: {
  schoolName?: string,
  areaId?: string,
  schoolId?: string,
  schoolIds?: string[],
  keyWords?: string,
  pageSize: number,
  curPage: number
}) =>
  request({
    // 获取学校列表
    url: '/v1/SchoolList',
    method: 'GET',
    params
  })

/**
 * 注册
 * @param data 
 * @returns 
 */
export const register = (data: any) =>
  request({
    url: '/v1/School/registration',
    method: 'POST',
    data
  })

/**
 * 取消注册
 * @param data 
 * @returns 
 */
export const unregister = (data: any) =>
  request({
    url: '/v1/School/unregister',
    method: 'POST',
    data
  })



export const addSchool = (data: any) =>
  request({
    // 添加/更新学校
    url: '/v1/School',
    method: 'POST',
    data
  })

export const querySchool = (schoolId: string) =>
  request({
    //  获取学校by schoolId
    url: `/v1/School/${schoolId}`,
    method: 'GET'
  })

export const deleteSchool = (schoolId: string) =>
  request({
    // 删除学校by schoolId
    url: `/v1/School/${schoolId}`,
    method: 'DELETE'
  })

/**
 * 获取平台学校级联列表
 * @param params 
 * @returns 
 */
export const getSchoolCascadeList = () => request({
  url: '/v1/schoolCascadeList',
  method: 'get',
  headers: {
    "Content-Type": "application/json",
  },
  //params: _.merge(params, {
  //disabledTips: true
  //})
});

/**
 * 获取平台地区级联列表
 * @param params 
 * @returns 
 */
export const getAreaCascadeList = () => request({
  url: '/v1/areaCascadeList',
  method: 'get',
  headers: {
    "Content-Type": "application/json",
  },
  //params: _.merge(params, {
  //disabledTips: true
  //})
});

export const querySchoolContract = (schoolContractId: string) =>
  request({
    //  学校合约
    url: `/v1/SchoolContract/${schoolContractId}`,
    method: 'GET'
  })

export const postSimpleSchoolContract = (data: any) =>
  request({
    // 保存合约
    url: '/v1/schoolContract',
    method: 'POST',
    data
  });


  export const postSchoolContract = (data: any) =>
    request({
      // 保存合约
      url: '/v1/school/schoolContract',
      method: 'POST',
      data
    });
  
  export const deleteSchoolContract = (schoolId: string) =>
    request({
      // 删除合约by schoolId
      url: `/v1/school/schoolContract/${schoolId}`,
      method: 'DELETE'
    })
  


export const getSchoolContractList = (params: {
  schoolContractId?: string,
  schoolContractNo?: string,
  keywords?: string,
  schoolContractTypes?: string[],
  returnedPercentBetweens?: number[],
  sharedPercentBetweens?: number[],
  pageSize: number,
  curPage: number
}) =>
  request({
    // 获取学校列表
    url: '/v1/SchoolContractList',
    method: 'GET',
    params
  })


export const postSchoolContractReturned = (data: any) =>
  request({
    // 添加/更新学校合约回款
    url: '/v1/SchoolContractReturned',
    method: 'POST',
    data
  })

export const querySchoolContractReturned = (schoolContractReturnedId: string) =>
  request({
    //  学校合约回款by schoolContractReturnedId
    url: `/v1/SchoolContractReturned/${schoolContractReturnedId}`,
    method: 'GET'
  })

export const deleteSchoolContractReturned = (schoolContractReturnedId: string) =>
  request({
    // 删除学校合约回款by schoolContractReturnedId
    url: `/v1/SchoolContractReturned/${schoolContractReturnedId}`,
    method: 'DELETE'
  })

export const getSchoolContractReturnedList = (params: {
  schoolContractId?: string,
  pageSize: number,
  curPage: number
}) =>
  request({
    // 获取学校合约回款列表
    url: '/v1/SchoolContractReturnedList',
    method: 'GET',
    params
  })

export const postSchoolContractShared = (data: any) =>
  request({
    // 添加/更新学校合约分成
    url: '/v1/SchoolContractShared',
    method: 'POST',
    data
  })

export const querySchoolContractShared = (schoolContractSharedId: string) =>
  request({
    //  学校合约分成by schoolContractSharedId
    url: `/v1/SchoolContractShared/${schoolContractSharedId}`,
    method: 'GET'
  })

export const deleteSchoolContractShared = (schoolContractSharedId: string) =>
  request({
    // 删除学校合约分成by schoolContractSharedId
    url: `/v1/SchoolContractShared/${schoolContractSharedId}`,
    method: 'DELETE'
  })

export const getSchoolContractSharedList = (params: {
  schoolContractId?: string,
  pageSize: number,
  curPage: number
}) =>
  request({
    // 获取学校合约分成列表
    url: '/v1/SchoolContractSharedList',
    method: 'GET',
    params
  })


export const getSchoolGroupList = (params: {
  schoolGroupId?: string,
  schoolGroupName?: string,
  mergeSchools?: boolean,
  pageSize: number,
  curPage: number
}) =>
  request({
    // 获取学校分组列表
    url: '/v1/schoolGroupList',
    method: 'GET',
    params
  })

export const postSchoolGroup = (data: {
  schoolGroupId: string,
  schoolGroupName: string,
  userId: string
}) =>
  request({
    // 添加/更新学校分组
    url: '/v1/schoolGroup',
    method: 'POST',
    data
  })

export const deleteSchoolGroup = (schoolGroupId: string) =>
  request({
    // 删除学校分组
    url: `/v1/schoolGroup/${schoolGroupId}`,
    method: 'DELETE'
  })

export const postSchoolSchoolGroup = (data: {
  schoolSchoolGroupId: string,
  schoolGroupId: string,
  schoolId: string
}) =>
  request({
    // 添加/更新学校分组中间表
    url: '/v1/schoolSchoolGroup',
    method: 'POST',
    data
  })

export const deleteSchoolSchoolGroup = (schoolSchoolGroupId: string) =>
  request({
    // 删除学校分组中间表
    url: `/v1/schoolSchoolGroup/${schoolSchoolGroupId}`,
    method: 'DELETE'
  })


