import request from '@/utils/request'

export const unregisterExamRoom = (examRoom: any) =>
  request({
    // 添加/更新考场信息
    url: '/v1/examRoom/unregister',
    method: 'POST',
    data: {
      examRoom

    }
  })



export const setExamRoom = (data: any) =>
  request({
    // 添加/更新考场信息
    url: '/v1/examRoom',
    method: 'POST',
    data
  })

export const getExamRoomList = (data: {
  schoolName?: string,
  areaId?: string,
  schoolId?: string,
  keyWords?: string,
  pageSize: number,
  curPage: number
}) =>
  request({
    // 获取考场信息列表
    url: '/v1/examRoomList',
    method: 'GET',
    params: data
  })

export const queryExamRoom = (examRoomId: string) =>
  request({
    //  获取考场信息by examRoomId
    url: `/v1/examRoom/${examRoomId}`,
    method: 'GET'
  })

export const deleteExamRoom = (examRoomId: string) =>
  request({
    // 删除考场信息by examRoomId
    url: `/v1/examRoom/${examRoomId}`,
    method: 'DELETE'
  })

export const upgradeSetting = (data: {
  examRoomId: string,
  upgradeLevel: string
}) =>
  request({
    // 升级配置
    url: '/v1/examRoom/software/upgradeSetting',
    method: 'POST',
    data
  })

export const updateSoftwareVersionInfo = (data: {
  examRoomId: string,
  softwareVersion: string
}) =>
  request({
    // 升级配置
    url: '/v1/examRoom/software/updateVersionInfo',
    method: 'POST',
    data
  })


export const getUpgradeTestServeInfo = () =>
  request({
    // 升级配置
    url: '/v1/upgradeTestServe',
    method: 'POST',
    data: {
      examRoomId: "",
      lastUpgradeHashCode: "",
      lastUpgradeProductVersion: ""
    }
  })





